<template>
	<div>
		<b-row>
			<b-col sm="12">
				<b-card>
					<b-row class="text-center mb-2">
						<b-col
							sm="4"
							md="2"
							class="my-auto"
						>
							<feather-icon
								icon="CalendarIcon"
								size="1.5x"
								class="mr-1"
								style="color: rgba(216, 164, 0, 0.85);"
							/>De
						</b-col>
						<b-col
							sm="8"
							md="3"
							class="my-auto"
						>
							<flat-pickr
								v-model="starDate"
								class="form-control"
								:config="configCalendar"
							/>
						</b-col>
						<b-col
							sm="4"
							md="1"
							class="my-auto"
						>a</b-col>
						<b-col
							sm="8"
							md="3"
							class="my-auto"
						>
							<flat-pickr
								v-model="endDate"
								class="form-control"
								:config="configCalendar"
							/>
						</b-col>
						<b-col
							sm="12"
							md="3"
							class="my-auto"
						>
							<b-button
								size="sm"
								variant="primary"
								@click="filterOne"
							>
								<feather-icon
									icon="FilterIcon"
									size="1x"
								/>Filtrar
							</b-button>
						</b-col>
					</b-row>

					<!-- sorting  -->
					<b-form-group
						label="Ordenar"
						label-size="sm"
						label-align-sm="left"
						label-cols-sm="2"
						label-for="sortBySelect1"
						class="mr-1 mb-md-0"
					>
						<b-input-group size="sm">
							<b-form-select
								id="sortBySelect1"
								v-model="sortBy"
								:options="sortOption1"
							>
								<template #first>
									<option value>Ninguno</option>
								</template>
							</b-form-select>
							<b-form-select
								v-model="sortDesc"
								size="sm"
								:disabled="!sortBy"
							>
								<option :value="false">Asc</option>
								<option :value="true">Desc</option>
							</b-form-select>
						</b-input-group>
					</b-form-group>

					<!-- filter -->
					<b-form-group
						label="Fitrar"
						label-cols-sm="2"
						label-align-sm="left"
						label-size="sm"
						label-for="filterInput1"
						class="mt-1"
					>
						<b-input-group size="sm">
							<b-form-input
								id="filterInput1"
								v-model="filter"
								type="search"
								placeholder="Buscar ..."
							/>
							<b-input-group-append>
								<b-button @click="clearFilter">Limpiar</b-button>
							</b-input-group-append>
						</b-input-group>
					</b-form-group>

					<!-- <json-excel
						:data="exportExcelTable()"
						name="evaluaciones_de_desempeno.xls"
					></json-excel>-->

					<b-button
						variant="outline-success"
						@click="exportTable"
						class="my-2"
						block
					>EXPORTAR EXCEL</b-button>

					<b-table
						ref="table1Ref"
						:fields="fields"
						:items="items"
						show-empty
						empty-text="No se encontraron registros coincidentes"
						striped
						hover
						responsive
						class="position-relative text-center"
						:per-page="perPage"
						:current-page="currentPage"
						:sort-by.sync="sortBy"
						:sort-desc.sync="sortDesc"
						:sort-direction="sortDirection"
						:filter="filter"
						:filter-included-fields="filterOn"
						@filtered="onFiltered1"
					>
						<template #head()="data">
							<div v-html="data.label"></div>
						</template>
					</b-table>
				</b-card>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import { Spanish as SpanishLocale } from "flatpickr/dist/l10n/es.js"

import JsonExcel from "@core/components/b-button-download-excel/BButtonDownloadExcel.vue"
import flatPickr from "vue-flatpickr-component"
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect/index.js"
import "flatpickr/dist/plugins/monthSelect/style.css"
import moment from "moment"
import axios from "@axios"

export default {
	components: {
		flatPickr,
		JsonExcel
	},
	data() {
		return {
			perPage: 50,
			pageOptions: [10, 15, 20, 25, 30],
			totalRows: 1,
			currentPage: 1,
			sortBy: "",
			sortDesc: false,
			sortDirection: "asc",
			filter: null,
			filterOn: [],
			fields: [],
			items: [],

			starDate: moment().format("MM-YYYY"),
			endDate: "",

			configCalendar: {
				plugins: [
					new monthSelectPlugin({
						shorthand: true,
						dateFormat: "m-Y"
					})
				],
				locale: SpanishLocale
			}
		}
	},
	computed: {
		sortOption1() {
			return this.fields
				.filter((f) => f.sortable)
				.map((f) => ({ text: f.label, value: f.key }))
		}
	},
	beforeMount() {
		this.getDataTableOne()
	},
	methods: {
		async getDataTableOne() {
			await axios
				.get(
					`/reporters/performance-evaluations?start_date=${this.starDate}&end_date=${this.endDate}`
				)
				.then((response) => {
					if (response) {
						this.fields = response.data.report.fields
						this.items = response.data.report.items

						this.totalRows = response.data.report.items.length
						this.currentPage = 1
						this.sortBy = ""
						this.sortDesc = false
						this.sortDirection = "asc"
						this.filter = null
					}
				})
		},
		async filterOne() {
			await this.getDataTableOne()
			this.$refs["table1Ref"].refresh()
		},
		exportExcelTable() {
			let array = []

			this.items.forEach((item, index) => {
				let objBody = {
					ITEM: index + 1,
					"MES/AÑO": item.month,
					RUBRO: item.business_name,
					PROVEEDOR: item.provider_name,
					SEGURIDAD: item.security,
					PRODUCTIVIDAD: item.productivity,
					"9 CAJAS - POSICIÓN": item.matrix,
					"RIESGO LABORAL": item.labor_risk,
					COLPA: item.colpa,
					"RIESGO FINANCIERO": item.financial_risk,
					"SBS/MICROF (DEUDA PENDIENTE)": item.debt_due,
					"SBS/MICROF (DEUDA VENCIDA)": item.overdue_debt,
					PROTESTOS: item.protests,
					"DOC IMPAGO": item.unpaid,
					LABORAL: item.labor,
					TRIBUTARIA: item.tributaria,
					"TOTAL DEUDA VENCIDA": item.total_debt,
					"9 CAJAS - POSICIÓN CALIBRADA": item.matrix_after_calibration,
					ROTACIÓN: item.rotation,
					"PENALIDADES (CANTIDAD)": item.penality_number,
					"PENALIDADES (MONTO)": item.penality_amount,
					"9 CAJAS - FINAL": item.performance_name
				}

				array.push(objBody)
			})

			return array
		},
		onFiltered1(filteredItems) {
			this.totalRows = filteredItems.length
			this.currentPage = 1
		},
		getFileName() {
			let filename = "reporte_desempeno"

			if (this.starDate && this.endDate) {
				filename += `_de_${this.starDate}_al_${this.endDate}`
			} else if (this.starDate) {
				filename += `_desde_${this.starDate}`
			} else if (this.endDate) {
				filename += `_hasta_${this.endDate}`
			}

			return filename
		},
		async exportTable() {
			try {
				const url = `/reporters/export-performance?start_date=${this.starDate}&end_date=${this.endDate}`
				await axios.get(url).then(async (_) => {
					const result = await axios.get(url, {
						responseType: "blob"
					})

					const urlBlob = window.URL.createObjectURL(new Blob([result.data]))
					const link = document.createElement("a")
					link.href = urlBlob
					link.setAttribute("download", `${this.getFileName()}.xlsx`)
					document.body.appendChild(link)
					link.click()

					document.body.removeChild(link)
					window.URL.revokeObjectURL(urlBlob)
				})
			} catch (error) {
				toastNotification(error.response.data.message, false)
			}
		},
		clearFilter() {
			this.filter = null
			this.starDate = ""
			this.endDate = ""
		}
	}
}
</script>

<style scoped>
input[type="text"] {
	border: none;
	border-radius: 0px;
	background: transparent;
	border-bottom: 1px solid #d8d6de;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
